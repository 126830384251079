import { useAuthStore } from '@/store/auth';

type postprocessType = () => Promise<void>;
type logoutReturn = () => Promise<void>;

const useLogout = (postProcess: postprocessType): logoutReturn => {
  const store = useAuthStore();
  const logout = async () => {
    await store.logout();
    await postProcess();
  };
  return logout;
};

export default useLogout;

<template>
  <Flex flex-direction="column" height="100%">
    <Flex flex-direction="row" align-center>
      <div class="mr-4">
        <Flex justify-center flex-direction="column">
          <AccountIcon :image-url="accountImage" size="m" class="mb-1" />
          <Modal v-model="isAccountImageModalOpen">
            <UploadImage
              round
              :src="cropTargetImage"
              @cropped="onCroppedImage"
            />
            <template v-slot:activator>
              <Button
                v-if="accountImage"
                @click="changeAccountImage"
                variant="text"
                size="xs"
              >
                画像を変更
              </Button>
              <UploadTextButton v-else @change-file="onLoadImage" />
            </template>
          </Modal>
        </Flex>
      </div>
      <div>
        <Typography size="l">
          {{ user?.nickname || user?.lastName + ' ' + user?.firstName }}
        </Typography>
        <Typography
          size="s"
          color="grey"
          class="mt-2 mypage-side-content-email"
        >
          {{ user?.email }}
        </Typography>
      </div>
    </Flex>

    <Box class="mt-8">
      <SideContentMenu :menus="menus" />
    </Box>
    <Typography size="s" color="grey" class="mb-4 mt-12">
      ワークスペース
    </Typography>
    <Box
      v-if="workspaces && workspaces.length !== 0"
      flex="1"
      overflow="hidden auto"
      min-height="30px"
    >
      <div
        v-for="workspace in workspaces"
        :key="workspace.workspaceId"
        class="py-2"
      >
        <TypographyEllipsis
          size="m"
          color="link"
          class="hover"
          @click="selectWorkspace(workspace.workspaceId)"
        >
          {{ workspace.workspaceName }}
        </TypographyEllipsis>
      </div>
    </Box>
    <BaseButton class="side-content-logout" @click="logout">
      <Icon name="logout" sides="20" class="mr-2" />
      <Typography size="m">ログアウト</Typography>
    </BaseButton>
  </Flex>
  <PageLoaing v-if="isPageLoading" />
</template>
<script>
import { defineComponent, ref } from 'vue';
import { MYPAGE_ROUTES, ROUTE_NAMES } from '@/router';
import Flex from '@/components/layout/Flex.vue';
import Box from '@/components/layout/Box.vue';
import AccountIcon from '@/components/ui/AccountIcon.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import Button from '@/components/ui/Button.vue';
import Icon from '@/components/ui/Icon.vue';
import Modal from '@/components/ui/Modal.vue';
import PageLoaing from '@/components/ui/PageLoading.vue';
import SideContentMenu from '@/components/ui/SideContentMenu.vue';
import Typography from '@/components/ui/Typography.vue';
import TypographyEllipsis from '@/components/ui/TypographyEllipsis.vue';
import UploadImage from '@/components/ui/UploadImage.vue';
import UploadTextButton from '@/components/ui/UploadTextButton.vue';
import useLogout from '@/composables/auth/logout';
import { useRouter } from 'vue-router';
import {
  fetchWorkspaces,
  useAccountImage,
  useMyPage
} from '@/composables/mypage';
import { useUserInfoStore } from '@/store/userInfo';

export default defineComponent({
  name: 'MypageSideMenu',
  components: {
    AccountIcon,
    BaseButton,
    Button,
    Box,
    Flex,
    Icon,
    Modal,
    PageLoaing,
    SideContentMenu,
    Typography,
    TypographyEllipsis,
    UploadImage,
    UploadTextButton
  },
  setup() {
    // NOTO: ワークスペース一覧のスクロース表示は、Flex:1の高さを超えた場合に表示されますが、一覧の下に更に項目を追加する場合、更に考慮が必要になるので注意
    const userInfoStore = useUserInfoStore();
    const user = ref(userInfoStore.user);
    const router = useRouter();
    const logout = useLogout(
      async () => await router.push({ name: ROUTE_NAMES.login })
    );
    const { workspaces } = fetchWorkspaces();
    const { selectWorkspace } = useMyPage();

    const {
      accountImage,
      cropTargetImage,
      isAccountImageModalOpen,
      changeAccountImage,
      onCroppedImage,
      onLoadImage,
      isPageLoading
    } = useAccountImage();

    userInfoStore.$subscribe(() => {
      user.value = userInfoStore.user;
    });

    return {
      user,
      logout,
      accountImage,
      cropTargetImage,
      isAccountImageModalOpen,
      changeAccountImage,
      onCroppedImage,
      onLoadImage,
      isPageLoading,
      workspaces,
      selectWorkspace,
      menus: [
        {
          label: 'アカウント情報',
          items: [
            {
              label: 'マイページTOP',
              toName: MYPAGE_ROUTES.top,
              name: MYPAGE_ROUTES.top,
              iconName: 'mypage'
            },
            {
              label: 'プロフィール編集',
              toName: MYPAGE_ROUTES.profile,
              name: MYPAGE_ROUTES.profile,
              iconName: 'user'
            },
            {
              label: 'パスワード設定',
              toName: MYPAGE_ROUTES.changePassword,
              name: MYPAGE_ROUTES.changePassword,
              iconName: 'lock-solid'
            }
          ]
        }
      ]
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/spacing';
@import '@/styles/effect';
@import '@/styles/layout';

.mypage-side-content-email {
  word-break: break-all;
}

.side-content-logout {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: $spacing-base * 2;
  text-align: left;
  @extend .hover-background;
}
</style>

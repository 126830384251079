import { AccountApi, UserInfoApi } from '@/api';
import { AccessProject, Account, JoinWorkspace, UserInfo } from '@/api/openapi';
import { roundNumber } from '@/common/formatter';
import { toast } from '@/components/ui/Toast';
import { fetchAccountAvatarData } from '@/composables/fetchImage';
import useLoading from '@/composables/loading';
import useModal from '@/composables/modal';
import { COMPANY_ROUTES, WORKSPACE_ROUTES } from '@/router';
import { useUserInfoStore } from '@/store/userInfo';
import axios from 'axios';
import { Ref, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

interface useAccountImageReturnType {
  accountImage: Ref<string | null>;
  cropTargetImage: Ref<string | null>;
  isPageLoading: Ref<boolean>;
  onCroppedImage: (args: cropImageArgs) => Promise<void>;
  onLoadImage: ({
    file,
    name,
    type
  }: {
    file: string;
    name: string;
    type: string;
  }) => void;
  changeAccountImage: () => void;
  isAccountImageModalOpen: Ref<boolean>;
}

interface useProfileReturnType {
  changeProfile: (args: changeProfileArgs) => Promise<void>;
  isLoading: Ref<boolean>;
  user: UserInfo;
}

interface useChangeEmailReturnType {
  user: UserInfo;
  submitChangingEmail: (args: submitChangeEmailArgs) => Promise<void>;
  isLoading: Ref<boolean>;
}

interface useChangePasswordReturnType {
  submitChangingPassword: (args: submitChangePasswordArgs) => Promise<void>;
  isLoading: Ref<boolean>;
}

interface WorkspaceAccountAvatar {
  image?: string | null;
}

interface WorkspaceAccounts {
  accounts?: Array<WorkspaceAccount>;
  num?: number;
}

type Workspace = JoinWorkspace & WorkspaceAccounts;
type WorkspaceAccount = Account & WorkspaceAccountAvatar;

interface fetchWorkspacesReturnType {
  workspaces: Ref<Array<Workspace> | undefined>;
}

interface cropImageArgs {
  imageData: HTMLCanvasElement;
  file: {
    file: HTMLCanvasElement;
    name: string;
    type: string;
  };
}

export const useAccountImage = (): useAccountImageReturnType => {
  const userInfoStore = useUserInfoStore();
  const userId = userInfoStore.user.id ?? 0;
  const accountImage = ref<string>('');
  const cropTargetImage = ref<string | null>('');
  const { isModalOpen, openModal, closeModal } = useModal();
  const filename = ref<string>('');
  const filetype = ref<string>('');
  const isPageLoading = ref(false);

  const onCroppedImage = async ({ imageData, file }: cropImageArgs) => {
    if (file) {
      filename.value = file.name.replace('jpg', 'jpeg');
      filetype.value = file.type;
    }
    closeModal();
    isPageLoading.value = true;
    imageData.toBlob(async blob => {
      try {
        if (blob) {
          const formData = new FormData();
          formData.append('file', blob, filename.value || 'file.png');
          const result = await axios.put(
            `${process.env.VUE_APP_API_BASE_URL}/accounts/${userId}/avatar-image`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          );
          isPageLoading.value = false;
          filename.value = '';
          filetype.value = '';
          if (200 <= result.status && result.status < 300) {
            accountImage.value = imageData.toDataURL();
            userInfoStore.setAccountImage(accountImage.value);
            toast({
              title: '完了',
              message: `画像を設定しました`,
              variant: 'success'
            });
          } else {
            throw new Error();
          }
        } else {
          throw new Error();
        }
      } catch (e) {
        toast({
          title: '失敗',
          message: `画像の設定に失敗しました`,
          variant: 'error'
        });
        isPageLoading.value = false;
        return;
      }
    }, filetype.value);
  };

  const onLoadImage = ({
    file,
    name,
    type
  }: {
    file: string;
    name: string;
    type: string;
  }) => {
    cropTargetImage.value = file;
    filename.value = name;
    filetype.value = type;
    openModal();
  };

  const changeAccountImage = () => {
    cropTargetImage.value = accountImage.value;
    openModal();
  };

  watch(
    () => userInfoStore.userInfo,
    async userInfo => {
      if (!userInfo.id) return;
      accountImage.value = await fetchAccountAvatarData(userInfo.id);
    },
    { immediate: true }
  );

  return {
    accountImage,
    cropTargetImage,
    isAccountImageModalOpen: isModalOpen,
    isPageLoading,
    changeAccountImage,
    onCroppedImage,
    onLoadImage
  };
};

interface changeProfileArgs {
  firstName: string;
  lastName: string;
  nickname: string;
  userDescription: string;
}

export const useProfile = (): useProfileReturnType => {
  const userInfoStore = useUserInfoStore();
  const user = userInfoStore.user;
  const submitChangeProfile = async (
    args: changeProfileArgs
  ): Promise<void> => {
    try {
      const result = await UserInfoApi.putUserinfoProfile({
        firstName: args.firstName,
        lastName: args.lastName,
        nickname: args.nickname,
        userDescription: args.userDescription
      });
      if (200 <= result.status && result.status < 300) {
        userInfoStore.setUserInfo({
          ...userInfoStore.userInfo,
          firstName: args.firstName,
          lastName: args.lastName,
          nickname: args.nickname,
          userDescription: args.userDescription
        });
        toast({
          title: '完了',
          message: 'プロフィールを編集しました',
          variant: 'success'
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: 'プロフィールの編集に失敗しました',
        variant: 'error'
      });
    }
  };
  const [isLoading, changeProfile] = useLoading(submitChangeProfile);
  return {
    isLoading,
    changeProfile,
    user
  };
};

interface submitChangeEmailArgs {
  email: string;
}

interface submitChangePasswordArgs {
  password: string;
}

export const useChangeEmail = (): useChangeEmailReturnType => {
  const userStore = useUserInfoStore();
  const user = userStore.user;
  const submit = async (args: submitChangeEmailArgs) => {
    try {
      const result = await UserInfoApi.putUserinfoAccount({
        email: args.email
      });
      if (200 <= result.status && result.status < 300) {
        userStore.changeEmail(args.email);
        toast({
          title: '完了',
          message: `アカウントを設定しました`,
          variant: 'success'
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: `アカウントの設定に失敗しました`,
        variant: 'error'
      });
    }
  };
  const [isLoading, submitChangingEmail] = useLoading(submit);
  return {
    user,
    submitChangingEmail,
    isLoading
  };
};

export const useChangePassword = (): useChangePasswordReturnType => {
  const submit = async (args: submitChangePasswordArgs) => {
    try {
      const result = await UserInfoApi.putUserinfoAccount({
        password: args.password
      });
      if (200 <= result.status && result.status < 300) {
        toast({
          title: '完了',
          message: `パスワードを設定しました`,
          variant: 'success'
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: `パスワードの設定に失敗しました`,
        variant: 'error'
      });
    }
  };
  const [isLoading, submitChangingPassword] = useLoading(submit);
  return {
    submitChangingPassword,
    isLoading
  };
};

export const fetchWorkspaces = (): fetchWorkspacesReturnType => {
  const workspaces = ref<Array<Workspace> | undefined>();
  const userStore = useUserInfoStore();
  workspaces.value = userStore.userInfo.workspaces;

  watch(
    () => userStore,
    async store => {
      workspaces.value = store.userInfo.workspaces;
    },
    { immediate: true }
  );

  return {
    workspaces
  };
};

interface useMyPageReturnType {
  selectCompany: (companyId: number) => Promise<void>;
  selectWorkspace: (workspaceId: number) => Promise<void>;
}

export const useMyPage = (): useMyPageReturnType => {
  const router = useRouter();
  const selectCompany = async (companyId: number) => {
    await router.push({
      name: COMPANY_ROUTES.top,
      params: { companyId }
    });
  };
  const selectWorkspace = async (workspaceId: number) => {
    await router.push({
      name: WORKSPACE_ROUTES.top,
      params: { workspaceId }
    });
  };

  return {
    selectCompany,
    selectWorkspace
  };
};

interface AccessProjectItem extends AccessProject {
  projectName: {
    name: string;
    projectId: string;
    companyId: string;
  };
  productList: string;
  cmSponsorList: string;
  targetList: string;
  areaName: string;
  startDate: string;
  endDate: string;
  individualGrpLabel: string;
  companyName: string;
}

interface UseAccessProjectsReturnType {
  accessProjectItems: Ref<AccessProjectItem[]>;
  isLoadingAccessProjects: Ref<boolean>;
  setAccessProjects: (accountId?: number) => Promise<void>;
}

export const useAccessProjects = (): UseAccessProjectsReturnType => {
  const accessProjectItems = ref<AccessProjectItem[]>([]);
  const _setAccessProjects = async (accountId?: number) => {
    if (!accountId) return;
    const res = await AccountApi.getAccountsAccountIdAccessProjects(accountId);
    if (!res.data.list) return;
    accessProjectItems.value = res.data.list
      .map(project => {
        return {
          ...project,
          projectName: {
            name: project.name,
            projectId: project.id.toString(),
            companyId: project.companyId.toString()
          },
          productList: project.productNames.join('、'),
          cmSponsorList:
            project.cmSponsorNames.length > 0
              ? project.cmSponsorNames.join('、')
              : '━',
          targetList: project.targetNames.join('、'),
          areaName: project.areaName,
          startDate: project.startDate,
          endDate: project.endDate,
          individualGrpLabel: project.individualGrp
            ? roundNumber(project.individualGrp, 1)
            : '━',
          companyName: project.companyName
        };
      })
      .filter(v => v !== undefined) as AccessProjectItem[];
  };
  const [isLoadingAccessProjects, setAccessProjects] = useLoading(
    _setAccessProjects
  );

  return {
    accessProjectItems,
    isLoadingAccessProjects,
    setAccessProjects
  };
};
